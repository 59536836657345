import {useState} from 'react';
import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from '@remix-run/node';
import {
  AppProvider as PolarisAppProvider,
  Button,
  Card,
  FormLayout,
  Page,
  Text,
  TextField,
} from '@shopify/polaris';
import polarisTranslations from '@shopify/polaris/locales/en.json';
import {Form, useActionData, useLoaderData} from '@remix-run/react';
import polaris from '@shopify/polaris/build/esm/styles.css?url';

import {login} from '../../shopify.server';

import {loginErrorMessage} from './error.server';

export const links = () => {
  return [{rel: 'stylesheet', href: polaris}];
};

export async function loader({request}: LoaderFunctionArgs) {
  const errors = loginErrorMessage(await login(request));

  return json({
    errors,
    polarisTranslations,
  });
}

export async function action({request}: ActionFunctionArgs) {
  const errors = loginErrorMessage(await login(request));

  return json({
    errors,
  });
}

export default function Auth() {
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const [shop, setShop] = useState('');
  const {errors} = actionData || loaderData;

  return (
    <PolarisAppProvider i18n={loaderData.polarisTranslations}>
      <Page>
        <Card>
          <Form method="post">
            <FormLayout>
              <Text variant="headingMd" as="h2">
                Log in
              </Text>
              <TextField
                type="text"
                name="shop"
                label="Shop domain"
                helpText="example.myshopify.com"
                value={shop}
                onChange={setShop}
                autoComplete="on"
                error={'shop' in errors ? errors.shop : undefined}
              />
              <Button submit>Log in</Button>
            </FormLayout>
          </Form>
        </Card>
      </Page>
    </PolarisAppProvider>
  );
}
